import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Home.css';
import './ThreeD.js';
import ThreeD from './ThreeD.js';

function Home() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [text, setText] = useState("");
  const [key, setKey] = useState("");
  const [connections, setConnections] = useState({}); // Store connections
  const [showThreeD, setShowThreeD] = useState(false);
  const [degree, setDegree] = useState(1);
  const token = localStorage.getItem('authToken'); 
  const username = localStorage.getItem('username');
  const [file, setFile] = useState(null);                                  

  const handleDegree = (event) => {
    setDegree(Number(event.target.value))
    console.log("Selected:", Number(event.target.value))
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Set the selected file in state
  };


  const fetchData = async () => {
    console.log(username);
    try {
      const response = await axios.get('https://erlang.cc/entity/list', {
        
        headers: { Authorization: `Bearer ${token}` }, // Include Authorization header
      });
      
      setData(response.data);
      console.log(response.data)
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
      if (err.response && err.response.status === 403) {
        // Handle 403 error due to token issues
        alert('Session expired or unauthorized. Please log in again.');
        localStorage.removeItem('authToken'); // Remove expired or invalid token
        // Optionally, redirect to login page
        window.location.href = '/login';
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDropdownChange = (event, itemId) => {
    const [optionID, selectedValue] = event.target.value.split(',');
    setConnections((prevConnections) => ({
      ...prevConnections,
      [itemId]: { optionID, selectedValue }, // Store optionID and selectedValue
    }));
  };
  
  const handleConnect = async (itemId) => {
    const connection = connections[itemId];
    if (connection) {
      const { optionID, selectedValue } = connection;
      console.log(`Connecting ${selectedValue} (optionID: ${optionID}) to item ${itemId}`);
      const response = await axios.post(
        'https://erlang.cc/entity/connect',
        {"entity1ID": optionID, "entity2ID": itemId},
        {headers: {Authorization: `Bearer ${token}`}}
      )
    } else {
      alert("Please select a value from the dropdown.");
    }
    await fetchData();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('key', key);
      formData.append('text', text);
      formData.append('degree', degree);
      formData.append('username', username);
      if (file) formData.append('file', file); // Append the file if it exists

      const response = await axios.post('https://erlang.cc/entity', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Form submitted successfully:', response.data);
      setText("");
      setKey("");
      setFile(null);
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }
    await fetchData();
  };

  const handleThreeD = () => {
    setShowThreeD(!showThreeD);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  if (showThreeD) {
    return (
      <div>
        <ThreeD />
      </div>
    );
  } else {
    return (
      
        <div className="Home">
          <h1>Erlang</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="key">Key:</label>
              <input
                type="text"
                id="key"
                name="key"
                value={key}
                onChange={(e) => setKey(e.target.value.toLowerCase())}
              />
            </div>
            <div>
              <label htmlFor="text">Text:</label>
              <input
                type="text"
                id="text"
                name="text"
                value={text}
                onChange={(e) => setText(e.target.value.toLowerCase())}
              />
            </div>
            <div>
            <label htmlFor="file">Upload File:</label>
            <input
              type="file"
              id="file"
              name="file"
              onChange={handleFileChange}
            />
          </div>
            <div>
              <label htmlFor="example-select">Choose a degree:</label>
              <select
                id="example-select"
                onChange={handleDegree}
                value={degree !== null ? degree : ""}
              >
                <option value="" disabled>Select a degree</option>
                <option value={1}>One</option>
                <option value={2}>Two</option>
                <option value={3}>Three</option>
              </select>
              {degree !== null && <p>You selected: {degree}</p>}
            </div>
            <button type="submit" disabled={!key || !text}>
              Submit
            </button>
          </form>
          <button className="end" onClick={handleThreeD}>Show Three D</button>
          <ul>
            {data?.entities?.slice().reverse().map((item) => (
              <li key={item._id}>
                {item?.degree}. {item.key} &rarr; {item.text}    |||| {item.file ? `File: ${item.file.slice(item.file.indexOf('-') + 1)}` : ''}

                {item.edges?.length > 0 && ( // Check if edges exist
                    <ul>
                        {item.edges.map((edge, index) => {
                          const relatedEntity = data.entities.find((entity) => entity._id === edge);
                          return (
                            <li key={index}>
                              {relatedEntity
                                ? `${relatedEntity.key} → ${relatedEntity.text}` // Display the related entity details
                                : "Entity not found"} {/* Fallback if no matching entity */}
                            </li>
                          );
                        })}
                    </ul>
                  )}
                <select
                  className="select-dropdown"
                  onChange={(event) => handleDropdownChange(event, item._id)}
                  value={
                    connections[item._id]
                      ? `${connections[item._id].optionID},${connections[item._id].selectedValue}`
                      : "" // Ensure dropdown value reflects the current connection
                  }
                >
                  <option value="" disabled>
                    Select Key → Text
                  </option>
                  {data?.entities?.map((option) => (
                    <option key={option._id} value={`${option._id},${option.key} → ${option.text}`}>
                      {option.degree}. {option.key} → {option.text}
                    </option>
                  ))}
                </select>
                <button onClick={() => handleConnect(item._id)}>Connect</button>
              </li>
            ))}
          </ul>

        </div>
            
    );
  }
}

export default Home;
