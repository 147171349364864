import './ThreeD.css';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera, Text } from '@react-three/drei';
import axios from 'axios';
import * as THREE from "three";


const CurvedTube = ({ pathVectors }) => {
  // Function to generate a consistent color based on the sum of path vectors
  const getColorFromPath = (pathVectors) => {
    // Calculate the sum of all path vectors' x, y, z coordinates
    let sum = 0;
    pathVectors.forEach(vector => {
      sum += vector.x + vector.y + vector.z;
    });

    // Use the sum as a seed for random color generation
    const r = (Math.sin(sum) * 0.5 + 0.5); // Generate a value between 0 and 1
    const g = (Math.cos(sum) * 0.5 + 0.5); // Generate a value between 0 and 1
    const b = (Math.sin(sum + Math.PI / 2) * 0.5 + 0.5); // Generate a value between 0 and 1

    return new THREE.Color(r, g, b);
  };

  // Ensure pathVectors is an array of THREE.Vector3 objects
  const curve = new THREE.CatmullRomCurve3(pathVectors);

  // Generate the color based on the path vectors
  const pathColor = getColorFromPath(pathVectors);

  return (
    <mesh>
      {/* Create TubeGeometry */}
      <tubeGeometry args={[curve, 100, 0.5, 8, false]} />
      {/* Add a material with the generated color */}
      <meshStandardMaterial color={pathColor} />
    </mesh>
  );
};

function Form({ onSubmit }) {
  const [key, setKey] = useState("");
  const [text, setText] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(key, text);
    setKey("");
    setText("");
  };

  const formStyle = {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 10,
  };

  return (
    <form onSubmit={handleSubmit} style={formStyle}>
      <input
        type="text"
        value={key}
        onChange={(e) => setKey(e.target.value)}
        placeholder="Key"
      />
      <input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Text"
      />
      <button type="submit">Submit</button>
    </form>
  );
}


function ThreeD() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null); // State for selected entity
  const [key, setKey] = useState("");
  const [text, setText] = useState("");
  const cameraRef = useRef();
  const controlsRef = useRef();
  let degree2 = 0;
  let degree3 = 0;

  const token = localStorage.getItem('authToken');

  const fetchData = async () => {
    try {
      const response = await axios.get('https://erlang.cc/entity/list', {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure 'Bearer' is included in the header
        },
      });
      setData(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // function calc_pos_deg_one_two(id, meshPositions){
  //   const entity = data.entities.find(e => e._id === item);
  //   let connected_hubs_positions = data?.entities.filter(e => e.edges.includes(id) && e.degree > entity.degree)
  //   // Find the connected hubs postions in mesh positinos average the 
  //   // put non connected hubs somewhere,
  //   // the ones connected have this average distribution going on for them
  // }

  // Array of mesh positions
  let meshPositions = [];
  if (data){
    console.log("_____________________________")
    console.log(data)
    console.log(data.entities.length)
    for (let x =0; x < data.entities.length; x++){
      if(data.entities[x].degree === 2){
        degree2++;
      }
      if(data.entities[x].degree === 3){
        degree3++;
      }
      // console.log(data.entities[x])
      // console.log(`numDegree2 ${degree2}`)
      // console.log(`numDegree3 ${degree3}`)
    }
    let degree3entities = data.entities.filter(e => e.degree ===3)
    // console.log(degree3entities)

    for (let y =0; y < degree3entities.length; y += 4){
      if(y < degree3entities.length){
        meshPositions.push([250 * (y + 1), 250 * (y + 1), 250, degree3entities[y]])
      }
      if(y + 1 < degree3entities.length){
        meshPositions.push([-250 * (y + 1), 250 * (y + 1), 250, degree3entities[y]])
      }
      if(y + 2 < degree3entities.length){
        meshPositions.push([250 * (y + 1), -250 * (y + 1), 250, degree3entities[y]])
      }
      if(y + 3 < degree3entities.length){
        meshPositions.push([-250 * (y + 1), -250 * (y + 1), 250, degree3entities[y]])
      }
    }
    // console.log(meshPositions)
    let degree2entities = data.entities.filter(e => e.degree ===2)
    // console.log(degree2entities)
    for (let y =0; y < degree2entities.length; y += 4){
      if(y < degree2entities.length){
        meshPositions.push([250 * (y + 1), 250 * (y + 1), 125, degree2entities[y]])
      }
      if(y + 1 < degree2entities.length){
        meshPositions.push([-250 * (y + 1), 250 * (y + 1), 125, degree2entities[y]])
      }
      if(y + 2 < degree2entities.length){
        meshPositions.push([250 * (y + 1), -250 * (y + 1), 125, degree2entities[y]])
      }
      if(y + 3 < degree2entities.length){
        meshPositions.push([-250 * (y + 1), -250 * (y + 1), 125, degree2entities[y]])
      }
    }
    // console.log(meshPositions)
    let degree1entities = data.entities.filter(e => e.degree ===1)
    let count = degree1entities.length
    // console.log(`count: ${count}`)
    let division = Math.floor(count /  18)
    let mod = count % 18
    let i;
    for (i = 0; i < division; i++) {
      
      meshPositions.push([0, (i+1) * 10, 0, degree1entities[(i*18)]]); // Positive Y-axis
      meshPositions.push([(i+1) * 10, 0, 0, degree1entities[(i*18) + 1]]); // Positive X-axis
      meshPositions.push([0, (i+1) * -10, 0, degree1entities[(i*18) + 2]]); // Negative Y-axis
      meshPositions.push([( 1+ i) * -10, 0, 0, degree1entities[(i*18) + 3]]); // Negative X-axis
      meshPositions.push([0, 0, (i + 1) * 10, degree1entities[(i*18) + 4]]); // Positive Z-axis
      meshPositions.push([0, 0, (i + 1) * -10, degree1entities[(i*18) + 5]]); // Negative Z-axis

      meshPositions.push([(i + 1) * 10, (i + 1) * 10, 0, degree1entities[(i*18) + 6]])
      meshPositions.push([(i + 1) * 10, (i + 1) * -10, 0, degree1entities[(i*18) + 7]])
      meshPositions.push([(i + 1) * -10, (i + 1) * 10, 0, degree1entities[(i*18) + 8]])
      meshPositions.push([(i + 1) * -10, (i+ 1) * -10, 0, degree1entities[(i*18) + 9]])

      meshPositions.push([(i + 1) * 10, (i + 1) * 10, (i + 1) * 10, degree1entities[(i*18) + 10]])
      meshPositions.push([(i + 1) * 10, (i + 1) * -10, (i + 1) * 10, degree1entities[(i*18) + 11]])
      meshPositions.push([(i + 1)  * -10, (i + 1)  * 10, (i + 1) * 10, degree1entities[(i*18) + 12]])
      meshPositions.push([(i + 1) * -10, (i + 1) * -10, (i + 1)  * 10, degree1entities[(i*18) + 13]])
      
      meshPositions.push([(i + 1)  * 10, (i + 1)  * 10, (i + 1) * -10, degree1entities[(i*18) + 14]])
      meshPositions.push([(i + 1)  * 10, (i + 1) * -10, (i + 1) * -10, degree1entities[(i*18) + 15]])
      meshPositions.push([(i + 1) * -10, (i + 1) * 10, (i + 1) * -10, degree1entities[(i*18) + 16]])
      meshPositions.push([(i + 1)  * -10, (i + 1)  * -10, (i + 1) * -10, degree1entities[(i*18) + 17]])
      
    }
    let j = 1;
    if (j <= mod){
      // console.log("division ::  " + division )
      meshPositions.push([0, (i + 1) * 10, 0, degree1entities[division * 18 + (j -1)]]); // Positive Y-axis
      j++;
    }
    if (j <= mod){
      meshPositions.push([(i + 1) * 10, 0, 0, degree1entities[division * 18 + (j -1)]]); // Positive X-axis
      j++;
    }
    if (j <= mod){
      meshPositions.push([0,(i + 1) * -10 , 0, degree1entities[division * 18 + (j -1)]]); // Positive X-axis
 // Positive X-axis
      j++;
    }
    if (j <= mod){
      meshPositions.push([(i + 1) * -10,0 , 0, degree1entities[division * 18 + (j -1)]]); // Positive X-axis
 // Positive X-axis
      j++;
    }
    
    if(j <= mod){
      meshPositions.push([0, 0, (i + 1) * 10, degree1entities[division * 18 + (j -1)]]); // Positive Z-axis
      j++
    }
    
    if(j <= mod){
      meshPositions.push([0, 0, (i + 1) * -10, degree1entities[division * 18 + (j -1)]]); // Negative Z-axis
      j++
    }
    
    if(j <= mod){
      meshPositions.push([(i + 1) * 10, (i + 1) * 10, 0, degree1entities[division * 18 + (j -1)]])
      j++  
    }
    
    if(j <= mod){
      meshPositions.push([(i + 1) * 10, (i + 1) * -10, 0, degree1entities[division * 18 + (j -1)]])
      j++
    }
    
    if(j <= mod){
      meshPositions.push([(i + 1) * -10, (i + 1) * 10, 0, degree1entities[division * 18 + (j -1)]])
      j++
    }
    
    if(j <= mod){
      meshPositions.push([(i + 1) * -10, (i + 1) * -10, 0, degree1entities[division * 18 + (j -1)]])
      j++
    }
    if(j <= mod){
      meshPositions.push([(i + 1) * 10, (i + 1) * 10, (i + 1) * 10, degree1entities[division * 18 + (j -1)]])
      j++
    }

    if(j <= mod){
      meshPositions.push([(i + 1) * 10, (i + 1) * -10, (i + 1) * 10, degree1entities[division * 18 + (j -1)]])
      j++
    }
    
    if(j <= mod){
      meshPositions.push([(i + 1) * -10, (i + 1) * 10, (i + 1) * 10, degree1entities[division * 18 + (j -1)]])
      j++
    }
    
    if(j <= mod){
      meshPositions.push([(i + 1) * -10, (i + 1) * -10, (i + 1) * 10, degree1entities[division * 18 + (j -1)]])
      j++
    }
    
    if(j <= mod){
      meshPositions.push([(i + 1) * 10, (i + 1) * 10, (i + 1) * -10, degree1entities[division * 18 + (j -1)]])
      j++
    }

    if(j <= mod){
      meshPositions.push([(i + 1) * 10, (i + 1) * -10, (i + 1) * -10, degree1entities[division * 18 + (j -1)]])
      j++
    }

    if(j <= mod){
      meshPositions.push([(i + 1) * -10, (i + 1) * 10, (i + 1) * -10, degree1entities[division * 18 + (j -1)]])
      j++
    }
    
    if(j <= mod){
      meshPositions.push([(i + 1) * -10, (i + 1) * -10, (i + 1) * -10, degree1entities[division * 18 + (j -1)]])
      j++
    }
    
  }

  console.log(meshPositions)
  console.log(meshPositions.length)

  const [cameraPosition, setCameraPosition] = useState([0, 0, 150]);
  const [orbitTarget, setOrbitTarget] = useState([0, 0, 0]);

  const handleKeyPress = useCallback(
    (event) => {
      const moveSpeed = 1;
      let newPosition = [...cameraPosition];

      switch (event.key) {
        case 'w':
          newPosition[2] -= moveSpeed;
          break;
        case 's':
          newPosition[2] += moveSpeed;
          break;
        case 'a':
          newPosition[0] -= moveSpeed;
          break;
        case 'd':
          newPosition[0] += moveSpeed;
          break;
        case 'ArrowUp':
          newPosition[1] += moveSpeed;
          break;
        case 'ArrowDown':
          newPosition[1] -= moveSpeed;
          break;
        default:
          break;
      }

      setCameraPosition(newPosition);
    },
    [cameraPosition]
  );

  const handleMeshClick = (index) => {
    if (data?.entities[index]) {
      setSelectedEntity(data.entities[index]); // Update selected entity
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.target.set(...orbitTarget);
      controlsRef.current.update();
    }
  }, [orbitTarget]);
  const handleCopyText = () => {
    const textToCopy = `erlang.cc/hub/${selectedEntity._id}`;
    
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('Link Copied!');
    }).catch((error) => {
      alert('Failed to copy text: ' + error);
    });
  };



  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div style={{ position: 'relative', height: '100vh' }}>
      <Canvas>
        <PerspectiveCamera makeDefault position={cameraPosition} ref={cameraRef} fov={75} near={0.1} far={5000} />
        <OrbitControls ref={controlsRef} minDistance={10} maxDistance={3000} />
        <ambientLight intensity={0.5} />
        <directionalLight position={[0, 0, 2]} />
        <directionalLight position={[0, 0, -2]} />
        {/* <CurvedTube pathVectors={[
    new THREE.Vector3(-40, 0, 0),
    new THREE.Vector3(-5, 5, 0),
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(5, -5, 0),
    new THREE.Vector3(30, 0, 0),
  ]}/> */}

        {data?.entities.map((entity, index) => {
          let position = meshPositions[index]
          // console.log(position)
          // console.log(`Positions: ${position[3]}`) 
          let scale = position[3].degree
          if (scale === 0){
            scale = 1
          }


          // find the hubs and nodes that we are connected to 
          let connected_hubs_positions = data?.entities.filter(e => e.edges.includes(position[3]._id))
          // console.log("Connected hubs postions")
          // console.log(connected_hubs_positions)
          return (
          <group key={index}>
          {connected_hubs_positions.map((connection, index) => {
            const position_connection = meshPositions.find(e => e[3]._id === connection._id);
            if(position_connection != null){
              return (
                <CurvedTube
                  key={index} // Add a unique key
                  pathVectors={[
                    new THREE.Vector3(position_connection[0], position_connection[1], position_connection[2]), // Use 'connection' to reference position
                    new THREE.Vector3(position[0], position[1], position[2]),
                  ]}
                />
              );
            }

          })}


            <mesh
              position={position}
              
              scale={[3 * (scale *scale), 3 * (scale * scale), 3 * (scale * scale)]}
              onClick={() => {
                handleMeshClick(data?.entities.findIndex(e => e == position[3]));
                setOrbitTarget(position);
              }}
            >
              <icosahedronGeometry args={[1, 0]} />
              <meshStandardMaterial color={selectedEntity?.key === position[3].key ? "blue" : "orange"} />
            </mesh>
            {data?.entities[index]?.key && (
              <Text
                position={[position[0], position[1] + 2.5 * (scale *scale), position[2]]}
                fontSize={ scale * 2}
                color="black"
                anchorX="center"
                anchorY="bottom"
              >
                {position[3].key}
                
              </Text>
            )}
          </group>
        )})}
      </Canvas>

      {/* Information Panel */}
      {selectedEntity && (
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            padding: '10px',
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '5px',
            maxWidth: '300px',
          }}
        >
          <h3>Entity Details</h3>
          <p><strong>Key:</strong> {selectedEntity.key}</p>
          <p><strong>Text:</strong> {selectedEntity.text}</p>
          <p><strong>Date:</strong> {selectedEntity.date}</p>
          <p><strong>Edges:</strong></p>
          {
            selectedEntity.edges.map((item) => {
              const entity = data.entities.find(e => e._id === item);
              return (
                <>
                
                <ul key={item}>
                  <li>
                    {entity ? entity.key : "Entity not found"}
                  </li>
                </ul>
                </>

              );
            })
          }

          {selectedEntity.degree === 3 && (
            <button onClick={() => handleCopyText()}>Share hub</button>
          )}
        </div>
      )}
      <Form/>
    </div>
  );
}

export default ThreeD;
