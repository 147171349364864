import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Components/Login';
import Home from './Components/Home';
import ThreeD from './Components/ThreeD';
import Hub from './Components/Hub';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        setIsAuthenticated(true);
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  const authenticate = (token) => {
    localStorage.setItem('authToken', token);
    setIsAuthenticated(true);
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={
          isAuthenticated ? <Navigate to="/" /> : <Login authenticate={authenticate} />
        } />
        
        <Route path="/" element={
          isAuthenticated ? <Home/> : <Navigate to="/login" />
        } />

        <Route path="/3d" element={
          isAuthenticated ? <ThreeD /> : <Navigate to="/login" />
        } />

        <Route path="/hub/:id" element={<Hub/>}/>

      </Routes>
    </Router>
  );
}

export default App;
