import './Login.css';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirecting

function Login({ authenticate }) {
  const [passkey, setPasskey] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [showSignup, setShowSignUp] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const handleSignUp = async (event) => {
    event.preventDefault();

    try{
      const response = await axios.post('https://erlang.cc/signup',{
        username, passkey
      })
      if (response.data.token) {
        // Save the token to localStorage
        localStorage.setItem('authToken', response.data.token);
        console.log(response.data.username)
        localStorage.setItem('username', response.data.username);

        // Call the authenticate function passed as a prop
        authenticate(response.data.token);

        // Redirect to home page after successful login
        navigate('/');
      }
    } catch (error){

    }
  }

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://erlang.cc/login', {
        username, passkey
      });

      if (response.data.token) {
        // Save the token to localStorage
        localStorage.setItem('authToken', response.data.token);
        console.log(response.data.username)
        localStorage.setItem('username', response.data.username);
        // Call the authenticate function passed as a prop
        authenticate(response.data.token);

        // Redirect to home page after successful login
        navigate('/');
      }
    } catch (error) {
      setError('Invalid passkey. Please try again.');
      console.error('Error logging in:', error);
    }
  };

  if (showSignup){
    return (
      <div className="login-container">
        <h2>Login</h2>
        <form className="login-form" onSubmit={handleSignUp}>
          <label htmlFor="username">Username</label>
          <input
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <label htmlFor="passkey">Passkey</label>
          <input
            type="password"
            id="passkey"
            placeholder="Enter your passkey"
            value={passkey}
            onChange={(e) => setPasskey(e.target.value)}
            required
          />
          <button type="submit">Sign Up</button>
        </form>
        {error && <p className="error-message">{error}</p>} {/* Show error if exists */}
        <button onClick={()=>{setShowSignUp(!showSignup)}}>Login</button>
      </div>
    );
  }else{
    return (
      <div className="login-container">
        <h2>Login</h2>
        <form className="login-form" onSubmit={handleLogin}>
        <label htmlFor="username">Username</label>
          <input
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <label htmlFor="passkey">Passkey</label>
          <input
            type="password"
            id="passkey"
            placeholder="Enter your passkey"
            value={passkey}
            onChange={(e) => setPasskey(e.target.value)}
            required
          />
          <button type="submit">Log In</button>
        </form>
        {error && <p className="error-message">{error}</p>} {/* Show error if exists */}
        <button onClick={()=>{setShowSignUp(!showSignup)}}>Sign Up</button>
      </div>
    );
  }


}

export default Login;
